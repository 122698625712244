const Bachelor = (props) => (
  <svg
    width="82"
    height="70"
    viewBox="0 0 82 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.352 41.4847V25.4847L43.29 34.762C41.6025 35.4456 39.7197 35.4456 38.0322 34.762L13.9702 25.4417V41.4417C13.9545 42.6761 14.5561 43.8362 15.5718 44.5355C20.9312 48.266 30.1738 50.7347 40.6618 50.7347C51.1498 50.7347 60.3918 48.2659 65.7518 44.5355C66.7557 43.8441 67.3573 42.7035 67.3534 41.4847H67.352ZM1.17996 18.4147L4.98856 19.8952V34.8132C3.85576 35.239 3.10966 36.3249 3.10966 37.5359V47.5359C3.10575 48.0242 3.29325 48.4968 3.637 48.8484C3.98075 49.2 4.4495 49.3992 4.9417 49.4031H6.9417C7.9651 49.3914 8.7855 48.5593 8.7815 47.5359V37.5359C8.7815 36.325 8.0315 35.239 6.8987 34.8132V20.6252L38.7107 32.9652C39.9607 33.4652 41.3591 33.4652 42.6091 32.9652L80.1401 18.4142C80.851 18.129 81.3198 17.4376 81.3198 16.6681C81.3198 15.8986 80.8511 15.2111 80.1401 14.922L42.6601 0.375C41.4062 -0.125 40.0117 -0.125 38.7617 0.375L1.1797 14.922C0.46876 15.2111 0 15.8986 0 16.6681C0 17.4376 0.46901 18.1295 1.17996 18.4147Z"
      fill="#D52A43"
    />
    <ellipse cx="41" cy="51.5" rx="12" ry="11.5" fill="#DAE6FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.9941 61.2411C37.1503 61.3201 33.6436 59.0238 32.1191 55.4368C30.5945 51.8454 31.3546 47.6788 34.0452 44.8865C36.7402 42.0941 40.8257 41.2379 44.3844 42.7131C47.9431 44.1927 50.2708 47.7095 50.2708 51.6215C50.3184 56.8813 46.168 61.184 40.9941 61.2411ZM47.4506 51.6215C47.4592 49.8478 46.7768 48.1442 45.559 46.8753C44.3584 45.624 42.7129 44.9215 40.9941 44.9215C39.2752 44.9215 37.6297 45.624 36.4291 46.8753C33.8767 49.5008 33.8767 53.7245 36.4291 56.35C37.6254 57.6101 39.2709 58.317 40.9941 58.317C42.7173 58.317 44.3627 57.6101 45.559 56.35C46.7769 55.0856 47.4549 53.3865 47.4506 51.6215ZM40.9941 47.1256C39.8323 47.1212 38.7181 47.5998 37.9104 48.4516C37.0899 49.3077 36.6364 50.4581 36.6364 51.6523C36.6278 52.8465 37.0856 53.9968 37.9104 54.8441C38.718 55.6915 39.8323 56.1701 40.9941 56.1701C42.1558 56.1701 43.2701 55.6915 44.0777 54.8441C44.9026 53.9968 45.3604 52.8465 45.3517 51.6523C45.3517 50.4581 44.8982 49.3078 44.0777 48.4516C43.2744 47.5866 42.1601 47.0993 40.9941 47.0906V47.1256ZM32.0629 58.9138L28.0723 66.0747H28.068C27.9773 66.2152 27.9773 66.404 28.068 66.5445C28.163 66.6894 28.3358 66.7552 28.4999 66.7157L31.929 65.9386C32.1406 65.8947 32.3523 66.022 32.4127 66.2328L33.5183 69.7056C33.5615 69.868 33.704 69.9866 33.8725 69.9954C34.0409 70.0217 34.205 69.9339 34.2827 69.7846L38.0531 63.0408C35.6647 62.3647 33.5615 60.9159 32.0629 58.9138ZM49.9294 58.9138H49.9251C48.4265 60.9027 46.3233 62.3384 43.948 63.0057L47.7183 69.7495H47.714C47.7961 69.8988 47.9602 69.9866 48.1243 69.9603C48.2927 69.9515 48.4352 69.8373 48.4784 69.6705L49.584 66.1976C49.6488 65.9869 49.8604 65.8596 50.0721 65.9035L53.5012 66.6806H53.4969C53.661 66.7245 53.838 66.6543 53.9287 66.5138C54.0238 66.3689 54.0238 66.1845 53.9287 66.0396L49.9294 58.9138Z"
      fill="#D52A43"
    />
  </svg>
);

export default Bachelor;
