import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const ThankYouPage = () => {
  const [showConfetti, setShowConfetti] = useState(true);
  const { width, height } = useWindowSize();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const storedName = localStorage.getItem('leadName');

  return (
    <div className="thank-you-container">
      {showConfetti && <Confetti width={width} height={height} />}
      <div className="thank-you-card">
        {/* Add Image */}
        <img
          src="/thank-you.jpg"
          alt="Thank You Illustration"
          className="thank-you-image"
        />
        <div className="thank-you-icon">
          <i className="fa fa-check-circle"></i>
        </div>
        <h1 className="thank-you-title">Thank You, {storedName}!</h1>
        <p className="thank-you-message">
        You're all set! Our expert team will connect with you shortly to guide you on your next steps
        </p>
      </div>

      <style jsx>{`
        .thank-you-container {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background-image: url('/ques_bg.png');
          background-size: cover; /* Ensure the image covers the entire container */
          background-repeat: no-repeat; /* Prevent repeating the image */
          background-position: center; /* Center the image */
          margin: 0;
          padding: 0;
          font-family: 'Lato', sans-serif;
        }

        .thank-you-card {
          background: #ffffff;
          border-radius: 12px;
          padding: 2rem;
          text-align: center;
          max-width: 500px;
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
          animation: fadeIn 0.8s ease-in-out;
          position: relative;
        }

        .thank-you-image {
          width: 100%;
          max-width: 400px;
          margin: -50px auto 20px;
          display: block;
          border-radius: 12px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        }

        .thank-you-icon {
          font-size: 4rem;
          color: #6a11cb;
          margin-bottom: 1rem;
        }

        .thank-you-title {
          font-size: 2.5rem;
          font-weight: bold;
          margin: 0 0 1rem;
          color: #333333;
        }

        .thank-you-message {
          font-size: 1.2rem;
          color: #555555;
          margin: 0;
          line-height: 1.6;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default ThankYouPage;
