const Master = (props) => (
  <svg
    width="82"
    height="51"
    viewBox="0 0 82 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.352 41.4847V25.4847L43.29 34.762C41.6025 35.4456 39.7197 35.4456 38.0322 34.762L13.9702 25.4417V41.4417C13.9545 42.6761 14.5561 43.8362 15.5718 44.5355C20.9312 48.266 30.1738 50.7347 40.6618 50.7347C51.1498 50.7347 60.3918 48.2659 65.7518 44.5355C66.7557 43.8441 67.3573 42.7035 67.3534 41.4847H67.352ZM1.17996 18.4147L4.98856 19.8952V34.8132C3.85576 35.239 3.10966 36.3249 3.10966 37.5359V47.5359C3.10575 48.0242 3.29325 48.4968 3.637 48.8484C3.98075 49.2 4.4495 49.3992 4.9417 49.4031H6.9417C7.9651 49.3914 8.7855 48.5593 8.7815 47.5359V37.5359C8.7815 36.325 8.0315 35.239 6.8987 34.8132V20.6252L38.7107 32.9652C39.9607 33.4652 41.3591 33.4652 42.6091 32.9652L80.1401 18.4142C80.851 18.129 81.3198 17.4376 81.3198 16.6681C81.3198 15.8986 80.8511 15.2111 80.1401 14.922L42.6601 0.375C41.4062 -0.125 40.0117 -0.125 38.7617 0.375L1.1797 14.922C0.46876 15.2111 0 15.8986 0 16.6681C0 17.4376 0.46901 18.1295 1.17996 18.4147Z"
      fill="#5331EF"
    />
  </svg>
);

export default Master;
