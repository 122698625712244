const Automobile = (props) => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M62.0948 27.2919L58.8146 12.9738L58.7922 12.8852C57.2781 7.39232 53.7077 4.72217 47.8764 4.72217H22.1241C16.2931 4.72217 12.7227 7.39232 11.2079 12.8852L7.90638 27.2919C3.02703 28.9845 0.334473 33.0277 0.334473 37.2036V48.8726C0.334473 51.0097 1.77857 52.7485 4.04692 52.7485V62.2338C4.04692 63.9152 5.41053 65.2781 7.09193 65.2781H14.0692C15.7502 65.2781 17.1138 63.9149 17.1138 62.2338V52.7485H34.9999H52.8866V62.2338C52.8866 63.9152 54.2499 65.2781 55.9309 65.2781H62.9082C64.5899 65.2781 65.9525 63.9149 65.9525 62.2338V52.7485H65.9532C68.2212 52.7485 69.6653 51.0097 69.6653 48.8726V37.2036C69.6656 33.0274 66.9731 28.9849 62.0948 27.2919ZM13.7234 43.6401H7.43633C5.56488 43.6401 4.04658 42.1228 4.04658 40.2507C4.04658 38.3785 5.56488 36.8606 7.43633 36.8606H13.7234C15.5955 36.8606 17.1131 38.3785 17.1131 40.2507C17.1131 42.1228 15.5955 43.6401 13.7234 43.6401ZM13.1189 26.4593C12.9355 26.4593 12.7619 26.376 12.6478 26.2325C12.5337 26.089 12.4907 25.9014 12.5316 25.7225L15.2291 13.9482C16.2584 10.2553 18.1313 8.88122 22.1237 8.88122H47.876C51.8685 8.88122 53.7417 10.2553 54.771 13.9482L57.4678 25.7222C57.5087 25.901 57.4657 26.0886 57.3519 26.2318C57.2375 26.3753 57.0642 26.4593 56.8808 26.4593C47.7759 26.4593 45.2332 26.4593 34.9995 26.4593C24.7669 26.4593 22.2238 26.4593 13.1189 26.4593ZM62.5634 43.6401H56.276C54.4046 43.6401 52.8866 42.1228 52.8866 40.2507C52.8866 38.3785 54.4046 36.8606 56.276 36.8606H62.5634C64.4352 36.8606 65.9528 38.3785 65.9528 40.2507C65.9528 42.1228 64.4352 43.6401 62.5634 43.6401Z"
      fill="#3DD278"
    />
  </svg>
);

export default Automobile;
