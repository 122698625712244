const Graduate2 = (props) => (
  <svg
    width="54"
    height="70"
    viewBox="0 0 54 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.1016 56.8125L32.1328 56.2891C33.1094 55.7891 33.7891 54.8437 33.9531 53.75L34.0859 52.8281C34.2031 51.9922 34.5313 51.1875 35.0234 50.5L35.2344 50.2031H33.5937L28.4062 52.9687L31.1016 56.8125Z"
      fill="#E36C06"
    />
    <path
      d="M27 42.1484C36.1797 42.1484 43.6484 34.6797 43.6484 25.5V20.9453C40.9219 21.8438 34.2656 23.7656 27 23.7656C18.8047 23.7656 12.8906 21.9609 10.3516 21.0156V25.4922C10.3516 34.6797 17.8203 42.1484 27 42.1484Z"
      fill="#E36C06"
    />
    <path
      d="M54 69.7891V62.0391C54 55.5078 48.6875 50.2031 42.1641 50.2031H37.5859L36.5781 51.6094C36.2656 52.0469 36.0547 52.5625 35.9766 53.1016L35.8438 54.0234C35.5938 55.7266 34.5312 57.2109 33 57.9922L31.2266 58.8984C31.0859 58.9688 30.9375 59 30.7891 59C30.4844 59 30.1875 58.8516 30.0078 58.5937L27.9531 55.6641V69.7813H47.4687V64.2969C47.4687 63.7656 47.8984 63.3359 48.4297 63.3359C48.9609 63.3359 49.3906 63.7656 49.3906 64.2969V69.7813H54V69.7891Z"
      fill="#E36C06"
    />
    <path
      d="M27 21.8516C34.5313 21.8516 41.5703 19.6406 43.6484 18.9219V18.6406C43.6484 18.6328 43.6484 18.6328 43.6484 18.625V12.5156L27.2891 17.7813C27.1953 17.8125 27.0938 17.8281 27 17.8281C26.9063 17.8281 26.8047 17.8125 26.7109 17.7813L10.3438 12.5156V18.6172C10.3438 18.625 10.3438 18.6328 10.3438 18.6328V18.9531C12.1641 19.6875 18.2969 21.8516 27 21.8516Z"
      fill="#E36C06"
    />
    <path
      d="M19.9141 52.8281L20.0469 53.75C20.2031 54.8359 20.8828 55.7891 21.8672 56.2891L22.8984 56.8125L25.5938 52.9687L20.4063 50.2031H18.7656L18.9766 50.5C19.4688 51.1875 19.7891 51.9922 19.9141 52.8281Z"
      fill="#E36C06"
    />
    <path
      d="M32.3438 48.7031V43.2734C30.6484 43.7813 28.8594 44.0625 27 44.0625C25.1406 44.0625 23.3516 43.7813 21.6562 43.2734V48.7031L27 51.5547L32.3438 48.7031Z"
      fill="#E36C06"
    />
    <path
      d="M5.99219 64.3047C5.99219 63.7734 6.42188 63.3437 6.95313 63.3437C7.48438 63.3437 7.91407 63.7734 7.91407 64.3047V69.7891H26.0469V55.6641L23.9922 58.5937C23.8047 58.8516 23.5078 59 23.2109 59C23.0625 59 22.9141 58.9688 22.7734 58.8984L21 57.9922C19.4688 57.2109 18.4063 55.7266 18.1563 54.0234L18.0234 53.1016C17.9453 52.5625 17.7422 52.0547 17.4219 51.6172L16.4141 50.2031H11.8359C5.3125 50.2031 0 55.5156 0 62.0391V69.7891H5.99219V64.3047Z"
      fill="#E36C06"
    />
    <path
      d="M48.0859 9.07813V16.2578C47.125 16.6406 46.4375 17.5703 46.4375 18.6641C46.4375 20.1016 47.6016 21.2656 49.0391 21.2656C50.4766 21.2656 51.6406 20.0938 51.6406 18.6641C51.6406 17.5703 50.9531 16.6406 49.9922 16.2578V8.47656C49.9922 8.46875 49.9922 8.46094 49.9844 8.45313L51.6328 7.92188L27 0L2.35156 7.9375L27 15.8672L48.0859 9.07813Z"
      fill="#E36C06"
    />
  </svg>
);

export default Graduate2;
