// UserContext.js
import React, { createContext, useContext, useState } from "react";

// Create the context
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  // Define the state you want to share across components
  const [userName, setUserName] = useState(" ");
  const [leadId, setLeadId] = useState(null);

  const [accessToken, setAccessToken] = useState(null);

  // Value to be passed to context consumers
  const value = {
    userName,
    setUserName,
    leadId,
    setLeadId,
    accessToken,
    setAccessToken,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

// Custom hook for using the context in any component
export const useUser = () => {
  return useContext(UserContext);
};
