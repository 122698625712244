const OutcomeOthers = (props) => (
  <svg
    width="53"
    height="73"
    viewBox="0 0 53 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.0046 53.3958C38.2698 52.0237 35.0442 50.8389 34.6935 47.4634C34.5313 45.9072 34.6633 44.0781 35.0749 42.1689C36.3657 40.7858 37.4351 38.7901 38.5311 35.7192C40.7132 34.8786 42.1039 33.01 42.7813 30.0104C43.1287 28.4739 42.6319 26.8946 41.4184 25.6617C41.5275 23.7975 41.4318 23.6956 41.3616 23.6211L41.2851 23.5454H11.7195V25.6507C10.3224 26.9373 9.79832 28.4037 10.1614 30.0104C10.8381 33.0089 12.2291 34.8776 14.4131 35.7192C15.5088 38.79 16.5774 40.7847 17.8664 42.1666C18.1572 43.5102 18.4534 45.4994 18.249 47.4634C17.8983 50.8389 14.6726 52.0237 10.9376 53.3958C5.8107 55.2785 0 57.4134 0 65.9083C0 68.5518 8.21253 72.2736 26.4711 72.2736C44.7297 72.2736 52.9422 68.5518 52.9422 65.9083C52.9422 57.4136 47.1315 55.2787 42.0046 53.3958ZM23.3526 68.4027L18.9926 50.6931L24.2819 52.8949L23.9569 55.8113L24.8846 55.7904L23.3526 68.4027ZM29.4497 55.8113L29.1248 52.8949L34.414 50.6931L30.054 68.4027L28.5219 55.7904L29.4497 55.8113Z"
      fill="#47D2D2"
    />
    <path
      d="M3.19168 11.6851L10.9363 15.0912V20.229H42.3669V15.0912L45.5282 13.7005V20.9327C44.8577 21.039 44.344 21.6001 44.344 22.273C44.344 22.8583 44.7309 23.3656 45.2903 23.5509L44.39 29.228C44.3306 29.6039 44.438 29.9842 44.6838 30.2714C44.9292 30.5597 45.2879 30.7251 45.6679 30.7251H48.0383C48.4183 30.7251 48.777 30.5597 49.0219 30.2714C49.2682 29.9843 49.3756 29.604 49.3162 29.228L48.4164 23.5509C48.9767 23.3657 49.3642 22.8583 49.3642 22.273C49.3642 21.6001 48.8499 21.039 48.1789 20.9327V12.5355L50.1108 11.6862C50.4913 11.5208 50.7374 11.1513 50.7374 10.7469C50.7374 10.3446 50.4919 9.97646 50.1116 9.80876L28.8613 0.463568C28.1634 0.155558 27.4199 0 26.651 0C25.8823 0 25.1389 0.155558 24.4416 0.463568L3.1914 9.80876C2.81139 9.97646 2.56592 10.3446 2.56592 10.7469C2.56578 11.1525 2.81182 11.5206 3.19168 11.6851Z"
      fill="#47D2D2"
    />
  </svg>
);

export default OutcomeOthers;
