


const DomainExpertise = (props) => (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M64.5556 4.7334H5.44441C2.43756 4.7334 0 7.17096 0 10.1778V16.3661C0 16.7955 0.348223 17.1439 0.777793 17.1439H69.2222C69.6519 17.1439 70 16.7957 70 16.3661V10.1778C70 7.17096 67.5624 4.7334 64.5556 4.7334ZM9.81709 12.6937C8.84775 12.6937 8.06217 11.908 8.06217 10.9388C8.06217 9.96959 8.84789 9.18387 9.81709 9.18387C10.7863 9.18387 11.572 9.96959 11.572 10.9388C11.5721 11.908 10.7864 12.6937 9.81709 12.6937ZM15.4433 12.6937C14.474 12.6937 13.6884 11.908 13.6884 10.9388C13.6884 9.96959 14.4741 9.18387 15.4433 9.18387C16.4125 9.18387 17.1983 9.96959 17.1983 10.9388C17.1984 11.908 16.4125 12.6937 15.4433 12.6937ZM21.0696 12.6937C20.1004 12.6937 19.3147 11.908 19.3147 10.9388C19.3147 9.96959 20.1004 9.18387 21.0696 9.18387C22.0389 9.18387 22.8245 9.96959 22.8245 10.9388C22.8245 11.908 22.0388 12.6937 21.0696 12.6937Z" fill="#CC3DBE"/>
<path d="M69.2222 20.2549H0.777793C0.348359 20.2549 0 20.6031 0 21.0327V59.8224C0 62.8292 2.43756 65.2668 5.44441 65.2668H64.5556C67.5624 65.2668 70 62.8292 70 59.8224V21.0327C70 20.6031 69.6519 20.2549 69.2222 20.2549ZM24.3637 39.0514L21.6489 47.4292C21.2454 48.6753 19.5657 48.899 18.8567 47.7848L16.7713 44.5069L14.6858 47.7851C13.9799 48.8939 12.298 48.6783 11.8936 47.4295L9.17902 39.0517C8.54533 37.0969 11.5041 36.1346 12.1384 38.0927L13.8344 43.3271L15.4587 40.7742C16.0687 39.8154 17.4725 39.8136 18.0837 40.7742L19.7079 43.3271L21.404 38.0926C22.0389 36.1335 24.9972 37.097 24.3637 39.0514ZM42.5924 39.0514L39.8779 47.4292C39.4744 48.675 37.7948 48.8991 37.0856 47.7848L35 44.5071L32.9146 47.7851C32.2087 48.8939 30.5268 48.6783 30.1224 47.4295L27.4079 39.0517C26.7742 37.0969 29.7329 36.1346 30.3673 38.0927L32.0633 43.3271L33.6875 40.7742C34.2975 39.8154 35.7014 39.8136 36.3125 40.7742L37.9367 43.3271L39.6327 38.0927C40.2663 36.1372 43.2271 37.0936 42.5924 39.0514ZM60.821 39.0514L58.1064 47.4292C57.7021 48.6772 56.0204 48.8945 55.3142 47.7848L53.2288 44.5069L51.1435 47.7848C50.4345 48.8988 48.7549 48.6754 48.3513 47.4292L45.6364 39.0514C45.0028 37.0963 47.9615 36.1344 48.5959 38.0924L50.292 43.327L51.9162 40.774C52.5263 39.8152 53.9301 39.8135 54.5412 40.774L56.1654 43.327L57.8614 38.0926C58.495 36.1372 61.4556 37.0936 60.821 39.0514Z" fill="#CC3DBE"/>
</svg>

)

export default DomainExpertise