import clsx from 'clsx';
import React, { memo } from 'react';
import { Row } from 'react-grid-system';
import Header from '../../components/Header';
import LayoutContainer from '../../components/LayoutContainer';
import Main from '../../components/Main';

import styles from './index.module.css';

const Notfound: React.FC = () => {
  const renderDefaultHome = () => {
    return (
      <>
        <Header />
        <div
          className={clsx(styles.background, 'w-100', 'flex', 'align-center')}
          style={{ border: '0px solid blue' }}
        >
          <Row
            style={{
              width: '100%',
              border: '0px solid brown',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              className={clsx('flex', 'justify-center', 'align-center')}
              style={{ fontSize: '36px', color: '#fff' }}
              onClick={() => {
                window.open('https://onlinejain.com');
              }}
            >
              Page Not Found
            </button>
          </Row>
        </div>
      </>
    );
  };
  return (
    <LayoutContainer className={''}>
      <Main className={''} style={{ border: '0px solid black' }}>
        {renderDefaultHome()}
      </Main>
    </LayoutContainer>
  );
};
Notfound.displayName = 'Notfound';

export default Notfound;
