const SelfFunded = (props) => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_106_2203)">
      <path d="M70 0H0V70H70V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M20.4168 21.8748C28.471 21.8748 35.0002 18.6102 35.0002 14.5832C35.0002 10.5561 28.471 7.2915 20.4168 7.2915C12.3627 7.2915 5.8335 10.5561 5.8335 14.5832C5.8335 18.6102 12.3627 21.8748 20.4168 21.8748Z"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 14.5835C5.8335 14.5835 5.8335 20.7648 5.8335 24.7918C5.8335 28.8189 12.3627 32.0835 20.4168 32.0835C28.4709 32.0835 35.0002 28.8189 35.0002 24.7918C35.0002 22.4066 35.0002 14.5835 35.0002 14.5835"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 24.7915C5.8335 24.7915 5.8335 30.9728 5.8335 34.9998C5.8335 39.0269 12.3627 42.2915 20.4168 42.2915C28.4709 42.2915 35.0002 39.0269 35.0002 34.9998C35.0002 32.6146 35.0002 24.7915 35.0002 24.7915"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 35C5.8335 35 5.8335 41.1813 5.8335 45.2083C5.8335 49.2354 12.3627 52.5 20.4168 52.5C28.4709 52.5 35.0002 49.2354 35.0002 45.2083C35.0002 42.8231 35.0002 35 35.0002 35"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 45.2085C5.8335 45.2085 5.8335 51.3898 5.8335 55.4168C5.8335 59.4439 12.3627 62.7085 20.4168 62.7085C28.4709 62.7085 35.0002 59.4439 35.0002 55.4168C35.0002 53.0316 35.0002 45.2085 35.0002 45.2085"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5833 42.2918C57.6375 42.2918 64.1667 39.0272 64.1667 35.0002C64.1667 30.9731 57.6375 27.7085 49.5833 27.7085C41.5292 27.7085 35 30.9731 35 35.0002C35 39.0272 41.5292 42.2918 49.5833 42.2918Z"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 35C35 35 35 41.1813 35 45.2083C35 49.2354 41.5292 52.5 49.5833 52.5C57.6374 52.5 64.1667 49.2354 64.1667 45.2083C64.1667 42.8231 64.1667 35 64.1667 35"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 45.2085C35 45.2085 35 51.3898 35 55.4168C35 59.4439 41.5292 62.7085 49.5833 62.7085C57.6374 62.7085 64.1667 59.4439 64.1667 55.4168C64.1667 53.0316 64.1667 45.2085 64.1667 45.2085"
        stroke="#D93D3D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_106_2203">
        <rect width="70" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SelfFunded;
