import React from 'react';
import { setConfiguration, ScreenClassProvider } from 'react-grid-system';
import { RouterProvider } from 'react-router-dom';


import ErrorBoundary from './components/ErrorBoundary';
import { BREAKPOINTS } from './config/constants';
import { UserProvider } from './context/userContext';
import router from './router';

import './styles/globals.scss';

setConfiguration({
  breakpoints: Object.values(BREAKPOINTS).map((v) => v + 1),
  containerWidths: [540, 750, 1000, 1440, 1540],
  maxScreenClass: 'xl',
  gutterWidth: 20,
});

const App: React.FC = () => (
  <ErrorBoundary>
    <ScreenClassProvider>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </ScreenClassProvider>
  </ErrorBoundary>
);
App.displayName = 'App';
export default App;
