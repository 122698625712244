import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { useParams, useNavigate } from 'react-router-dom';

import {
  useStaticData,
  BACHELORS,
  MASTERS,
  HIGHESTEDUCATION,
  EXPECTEDOUTCOME,
  PREFERREDPAYMENT,
  WORKEXPERIENCE,
  WORKINDUSTRY,
  COURSESTART,
  SALARYRANGE,
} from '../api/useStaticData';
import Header from '../components/Header';
import LayoutContainer from '../components/LayoutContainer';
import Main from '../components/Main';
import Automobile from '../icons/automobile';
import Bachelor from '../icons/bachelor';
import BFSI from '../icons/bfsi';
import DomainExpertise from '../icons/domainexpertise';
import Education from '../icons/education';
import EmployerFunding from '../icons/employerfunding';
import Graduate1 from '../icons/graduate1';
import Graduate2 from '../icons/graduate2';
import Graduate3 from '../icons/graduate3';
// import Graduate4 from '../icons/graduate4';
import Graduate5 from '../icons/graduate5';
import Healthcare from '../icons/healthcare';
import Immediately from '../icons/immediately';
import IT from '../icons/it';
import JobChange from '../icons/jobchange';
import Manufacturing from '../icons/manufacturing';
import Master from '../icons/master';
import NeedLoanAssistance from '../icons/needloanassistance';
import NotSure from '../icons/notsure';
import OutcomeOthers from '../icons/outcomeothers';
import Retail from '../icons/retail';
import SalaryHike from '../icons/salaryhike';
import SelfFunded from '../icons/selffunded';
import Within15Days from '../icons/within15days';
import WithinAMonth from '../icons/withinamonth';
import WithinAWeek from '../icons/withinaweek';
import styles from '../styles/Persona.module.scss';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const STEP_NAMES = [
  { name: 'Select Language', key: 'language' },
  { name: 'Education Level', key: 'highestEducationCompleted' },
  { name: 'Combination', key: 'higestEducationCombination' },
  { name: 'Percentage', key: 'nextHighestEducationQualificationPercentage' },
  { name: 'Work Experience', key: 'workExperience' },
  { name: 'Industry', key: 'currentIndustry' },
  { name: 'Salary Range', key: 'salaryRange' },
  { name: 'Expected Outcome', key: 'expectedOutcome' },
  { name: 'Funding', key: 'preferredPayment' },
  { name: 'Course Start', key: 'courseStart' },
];

const Persona = () => {
  const [bachelorOrMaster, setBachelorOrMaster] = useState(null);
  const { data } = useStaticData(bachelorOrMaster);
  const [curQ, setCurQ] = useState(0);
  const navigate = useNavigate();

  const [validationError, setValidationError] = useState('');

  const [userChoiceMade, setUserChoiceMade] = useState(
    new Array(STEP_NAMES.length).fill(false),
  );
  const [capturedData, setCapturedData] = useState(
    new Array(STEP_NAMES.length).fill(null),
  );
  const [selectedCard, setSelectedCard] = useState(null);

  const leadName = localStorage.getItem('leadName');

  const buttonClicked = async () => {
    const currentStepKey = STEP_NAMES[curQ].key;
    const currentValue = capturedData[curQ]?.value;

    if (!userChoiceMade[curQ]) {
      return;
    }

    setValidationError('');

    try {
      await logStepData(currentStepKey, { [currentStepKey]: currentValue });

      if (curQ === 1 && capturedData[curQ]?.choice === '12th') {
        resetCardSelection();
        setCurQ((prev) => prev + 2); // Skip Combination step
        return;
      }

      if (curQ === STEP_NAMES.length - 1) {
        navigate('/thankyou');
      } else {
        resetCardSelection();
        setCurQ((prev) => prev + 1);
      }
    } catch (error) {
      console.error(`Error logging step: ${currentStepKey}`, error.message);
    }
  };
  const resetCardSelection = () => {
    setSelectedCard(null); // Reset the selected card
    setUserChoiceMade((prev) => {
      const updatedChoices = [...prev];
      updatedChoices[curQ] = false; // Clear the choice made for the current step
      return updatedChoices;
    });
  };

  const logStepData = async (stepKey, stepData) => {
    const storedPhone = localStorage.getItem('phone');
    // console.log(storedPhone);

    const payload = {
      phone: storedPhone,
      ...stepData,
    };

    if (stepKey === 'courseStart') {
      payload.persona_filled = 'Yes';
    }
    console.log('Logging step data:', payload);

    try {
      await axios.put('https://lead.learn.online/api/v1.0/lead/', payload);
    } catch (error) {
      console.error(`Error logging step: ${stepKey}`, error.message);
    }
  };

  const storedUniversity = localStorage.getItem('university');

  const renderMain = () => {
    const isExperienceTab = (choice) => {
      return [
        WORKEXPERIENCE.YEARS01,
        WORKEXPERIENCE.YEARS12,
        WORKEXPERIENCE.YEARS24,
        WORKEXPERIENCE.YEARS46,
        WORKEXPERIENCE.YEARS710,
        WORKEXPERIENCE.YEARS10PLUS,
      ].includes(choice);
    };

    const isExperienceSectorTab = (choice) => {
      return [
        WORKINDUSTRY.IT,
        WORKINDUSTRY.BFSI,
        WORKINDUSTRY.EDUCATION,
        WORKINDUSTRY.HEALTHCARE,
        WORKINDUSTRY.RETAIL,
        WORKINDUSTRY.MANUFACTURING,
        WORKINDUSTRY.AUTOMOBILE,
        WORKINDUSTRY.OTHERS,
      ].includes(choice);
    };

    const isSalaryTab = (choice) => {
      return [
        SALARYRANGE.LAKHS3,
        SALARYRANGE.LAKHS6,
        SALARYRANGE.LAKHS12,
        SALARYRANGE.LAKHS12PLUS,
      ].includes(choice);
    };

    const isOutcomeTab = (choice) => {
      return [
        EXPECTEDOUTCOME.SALARYHIKE,
        EXPECTEDOUTCOME.DOMAINEXPERTISE,
        EXPECTEDOUTCOME.JOBCHANGE,
        EXPECTEDOUTCOME.OTHERS,
      ].includes(choice);
    };

    const isFundingTab = (choice) => {
      return [
        PREFERREDPAYMENT.SELFFUNDED,
        PREFERREDPAYMENT.EMPLOYERFUNDING,
        PREFERREDPAYMENT.LOANASSISTANCE,
      ].includes(choice);
    };

    const isStartingTab = (choice) => {
      return [
        COURSESTART.IMMEDIATELY,
        COURSESTART.WITHINAWEEK,
        COURSESTART.WITHIN15DAYS,
        COURSESTART.WITHINAMONTH,
        COURSESTART.NOTSURE,
      ].includes(choice);
    };

    const getSvg = (choice, index, miniature = false) => {
      if (
        [HIGHESTEDUCATION.BACHELORS, HIGHESTEDUCATION.MASTERS].includes(choice)
      ) {
        return index % 2 === 0 ? <Bachelor /> : <Master />;
      }

      if (isExperienceTab(choice)) {
        return null;
      }

      if (isOutcomeTab(choice)) {
        if (index === 0) {
          return <SalaryHike />;
        }
        if (index === 1) {
          return <DomainExpertise />;
        }
        if (index === 2) {
          return <JobChange />;
        }
        if (index === 3) {
          return <OutcomeOthers />;
        }
      }

      if (isFundingTab(choice)) {
        if (index === 0) {
          return <SelfFunded />;
        }
        if (index === 1) {
          return <EmployerFunding />;
        }
        if (index === 2) {
          return <NeedLoanAssistance />;
        }
      }

      if (isStartingTab(choice)) {
        if (index === 0) {
          return <Immediately />;
        }
        if (index === 1) {
          return <WithinAWeek />;
        }
        if (index === 2) {
          return <Within15Days />;
        }
        if (index === 3) {
          return <WithinAMonth />;
        }
        if (index === 4) {
          return <NotSure />;
        }
      }

      if (isExperienceSectorTab(choice)) {
        if (index === '00') {
          if (miniature === true) {
            return <IT width={'40px'} height={'30px'} />;
          }
          //return <IT />;
          return <IT width={'40px'} height={'30px'} />;
        }
        if (index === '01') {
          //return <BFSI />;
          return <BFSI width={'40px'} height={'30px'} />;
        }
        if (index === '03') {
          return <Education width={'40px'} height={'30px'} />;
        }
        if (index === '02') {
          return <Healthcare width={'40px'} height={'30px'} />;
        }
        if (index === '10') {
          return <Retail width={'40px'} height={'30px'} />;
        }

        if (index === '11') {
          return <Manufacturing width={'40px'} height={'30px'} />;
        }
        if (index === '12') {
          return <Automobile width={'40px'} height={'30px'} />;
        }
        if (index === '13') {
          return <OutcomeOthers width={'40px'} height={'30px'} />;
        }
      }

      if (isSalaryTab(choice)) {
        return null;
      }

      if (index % 4 === 0) {
        return <Graduate1 />;
      }
      if (index % 4 === 1) {
        return <Graduate2 />;
      }
      if (index % 4 === 2) {
        return <Graduate3 />;
      }
      if (index % 4 === 3) {
        return <Graduate5 />;
      }

      return index % 2 === 0 ? <Bachelor /> : <Master />;
    };

    const getText = (choice) => {
      if (choice === WORKEXPERIENCE.YEARS01) {
        return (
          <div style={experienceStyle}>
            <h3 style={experienceTextStyle}>0-1</h3>
            <p style={experienceSubTextStyle}>Years</p>
          </div>
        );
      }

      if (choice === WORKEXPERIENCE.YEARS12) {
        return (
          <div style={experienceStyle}>
            <h3 style={experienceTextStyle}>1-2</h3>
            <p style={experienceSubTextStyle}>Years</p>
          </div>
        );
      }

      if (choice === WORKEXPERIENCE.YEARS24) {
        return (
          <div style={experienceStyle}>
            <h3 style={experienceTextStyle}>2-4</h3>
            <p style={experienceSubTextStyle}>Years</p>
          </div>
        );
      }

      if (choice === WORKEXPERIENCE.YEARS46) {
        return (
          <div style={experienceStyle}>
            <h3 style={experienceTextStyle}>4-6</h3>
            <p style={experienceSubTextStyle}>Years</p>
          </div>
        );
      }

      if (choice === WORKEXPERIENCE.YEARS710) {
        return (
          <div style={experienceStyle}>
            <h3 style={experienceTextStyle}>7-10</h3>
            <p style={experienceSubTextStyle}>Years</p>
          </div>
        );
      }

      if (choice === WORKEXPERIENCE.YEARS10PLUS) {
        return (
          <div style={experienceStyle}>
            <h3 style={experienceTextStyle}>10+</h3>
            <p style={experienceSubTextStyle}>Years</p>
          </div>
        );
      }

      if (choice === BACHELORS.BCOM) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MCOM;
        }
      }
      if (choice === BACHELORS.BCA) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MCA;
        }
      }
      if (choice === BACHELORS.BBA) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MBA;
        }
      }
      if (choice === BACHELORS.BSC) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MSC;
        }
      }
      if (choice === BACHELORS.BTECH) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MTECH;
        }
      }

      return choice;
    };

    const experienceStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%', // Full width within its parent container
      height: '100%', // Full height for consistent sizing
      transition: 'transform 0.3s ease-in-out', // Smooth scaling effect
      cursor: 'pointer', // Pointer for interactivity
    };

    const experienceTextStyle = {
      fontSize: '20px', // Larger, bold font for main text
      fontWeight: 'bold',
      color: '#3498db', // Blue text color
      margin: 0,
    };

    const experienceSubTextStyle = {
      fontSize: '14px', // Smaller text for the subtext
      color: '#555', // Muted text color
      margin: 0,
    };
    const renderCardBasedOnTab = (currentIndex) => {
      const response = data[curQ]?.response || [];

      return (
        <div
          className={clsx('grid-container')}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))', // Responsive grid
            gap: '20px', // Space between items

            maxWidth: '900px', // Limit grid width
            margin: '0 auto', // Center alignment
            justifyContent: 'center', // Align items in the center
            overflowX: 'hidden', // Prevent horizontal scrolling
          }}
        >
          {response.map((item, index) => cardBasedonChoice(item, index, curQ))}
        </div>
      );
    };

    const cardSelected = (choice, currentIndex) => {
      if (curQ === 1) {
        setBachelorOrMaster(choice === '12th' ? null : choice); // Update bachelorOrMaster dynamically
      }

      const valueToUpdate = {
        choice, // Store the selected choice
        value: choice, // Map to the correct response value
      };

      setCapturedData((prev) => {
        const updatedData = [...prev];
        updatedData[currentIndex] = valueToUpdate;
        return updatedData;
      });

      setUserChoiceMade((prev) => {
        const updatedChoices = [...prev];
        updatedChoices[currentIndex] = true;
        return updatedChoices;
      });

      setSelectedCard(choice);
      setValidationError('');
    };

    const cardBasedonChoice = (choice, index) => {
      const isSelected = selectedCard === index;

      // Logic to skip icons for specific steps
      const shouldShowIcon = !(curQ === 0 || curQ === 3); // Skip icons for language (0) and percentage (3)

      return (
        <div
          onClick={() => {
            cardSelected(choice, curQ);
            setSelectedCard(index);
          }}
          style={{
            border: isSelected ? '2px solid #3498db' : '1px solid #ddd',
            borderRadius: '12px',
            background: isSelected ? '#f0f8ff' : '#fff',
            boxShadow: isSelected
              ? '0px 6px 15px rgba(52, 152, 219, 0.4)'
              : '0px 2px 6px rgba(0, 0, 0, 0.1)',
            padding: '16px',
            transition: 'all 0.3s ease-in-out',
            cursor: 'pointer',
            textAlign: 'center',
            display: 'flex', // Flexbox for centering content
            flexDirection: 'column',
            justifyContent: 'center', // Vertical center
            alignItems: 'center', // Horizontal center
            overflow: 'hidden',
            width: '100%', // Ensures the card fits within the grid
            minWidth: '120px', // Minimum width for smaller screens
            height: 'auto', // Allows the card height to adjust dynamically
            minHeight: '100px', // Ensure consistent card size
            wordWrap: 'break-word', // Break long words
          }}
          id={`qw${index}`}
        >
          {shouldShowIcon && getSvg(choice, index) && (
            <div
              className={clsx('flex', 'justify-center')}
              style={{
                height: '80px',
                marginBottom: '12px',
                width: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {getSvg(choice, index)}
            </div>
          )}

          <h3
            style={{
              fontSize: '14px', // Smaller font size for mobile
              fontWeight: isSelected ? 'bold' : 'normal',
              color: isSelected ? '#3498db' : '#333',
              margin: '0',
              lineHeight: '1.2', // Adjust line height for better readability
              whiteSpace: 'normal', // Allow wrapping of text
              textOverflow: 'ellipsis', // Add ellipsis for overflow
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2, // Show a maximum of 2 lines
              WebkitBoxOrient: 'vertical',
            }}
          >
            {getText(choice)}
          </h3>
        </div>
      );
    };

    const getTitle = (existingTitle, currentIndex) => {
      if (currentIndex === 1) {
        return existingTitle.replace('PLACEHOLDER', bachelorOrMaster || '');
      }

      if (currentIndex === 2) {
        const nextHighestQualification =
          bachelorOrMaster === HIGHESTEDUCATION.MASTERS
            ? 'Masters'
            : bachelorOrMaster === HIGHESTEDUCATION.BACHELORS
              ? 'Bachelors'
              : null; // Default to null if undefined

        // If no selection, fallback to '12th'
        return existingTitle.replace(
          'PLACEHOLDER',
          nextHighestQualification || '12th',
        );
      }

      return existingTitle;
    };

    return (
      <div
        className={clsx('w-100', 'flex')}
        style={{
          border: '0px solid green',
          backgroundImage: 'url("/ques_bg.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Header university={storedUniversity} />
        <Row
          className={clsx('w-100', 'flex', 'justify-center')}
          style={{ border: '0px solid yellow', margin: '60px 0 0 0' }}
        >
          <Col>
            <Row
              style={{
                margin: '50px 0 3px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              className={styles.stepIndicator}
            >
              {/* Progress Bar */}
              <div
                style={{
                  width: '100%',
                  height: '10px',
                  background: '#e0e0e0',
                  borderRadius: '5px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    width: `${((curQ + 1) / STEP_NAMES.length) * 100}%`,
                    height: '100%',
                    background: '#3498db',
                    borderRadius: '5px',
                    transition: 'width 0.3s ease-in-out',
                  }}
                ></div>
              </div>

              {/* Progress Text */}
              <div
                style={{
                  marginTop: '10px',
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                {curQ === 0 &&
                  `Let’s get started, ${leadName}! Take the first step toward completing your journey.`}
                {curQ > 0 &&
                  curQ < STEP_NAMES.length * 0.25 &&
                  'You’ve made a great start! Small steps lead to big achievements.'}
                {curQ >= STEP_NAMES.length * 0.25 &&
                  curQ < STEP_NAMES.length * 0.5 &&
                  'Fantastic progress! You’re building momentum, keep going strong.'}
                {curQ >= STEP_NAMES.length * 0.5 &&
                  curQ < STEP_NAMES.length * 0.75 &&
                  `You're ${Math.round(((curQ + 1) / STEP_NAMES.length) * 100)}% done! You’re more than halfway there. Stay focused!`}
                {curQ >= STEP_NAMES.length * 0.75 &&
                  curQ < STEP_NAMES.length - 1 &&
                  `Almost there! Just a little more effort and you'll reach the finish line.`}
                {curQ === STEP_NAMES.length - 1 &&
                  "Final step! You're almost there—submit now to finish the process!"}
              </div>
            </Row>

            <Row>
              <div
                className={clsx('w-100', 'flex', 'justify-center')}
              
              >
                {data && data[curQ]?.qm && (
                  <h1 className={styles.title}>
                    {getTitle(data[curQ]['qm'], curQ)}
                  </h1>
                )}
              </div>
            </Row>

            <Row>
              {data[curQ]?.qs && (
                <div className={clsx('w-100', 'flex', 'justify-center')}>
                  <p className={styles.subtitle}>{data[curQ]['qs']}</p>
                </div>
              )}
            </Row>

            <Row
              className="row"
              style={{
                width: '100%',
                padding: '10px 0',
                marginLeft: '0px!important',
                marginBottom: '6rem',
              }}
            >
              <div
                className={clsx('flex', 'justify-center', styles.options)}
                style={{ margin: '0' }}
              >
                {renderCardBasedOnTab(curQ)}
              </div>
            </Row>

            <Row>
              <div
                className={clsx('fixed-button-container')}
                style={{
                  position: 'fixed',
                  bottom: '0',
                  width: '100%',
                  background: '#fff',
                  padding: '10px 20px',
                  boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for better visibility
                  zIndex: 1000,
                }}
              >
                {validationError && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '10px',
                      textAlign: 'center',
                    }}
                  >
                    {validationError}
                  </p>
                )}

                <button
                  onClick={buttonClicked}
                  disabled={!userChoiceMade[curQ]} // Disable if no choice made
                  style={{
                    display: 'flex', // Flexbox for icon and text alignment
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%', // Full-width button
                    padding: '15px',
                    border: 'none',
                    borderRadius: '8px',
                    background: !userChoiceMade[curQ] ? '#ccc' : '#3498db', // Grey background when disabled
                    color: !userChoiceMade[curQ] ? '#666' : '#fff', // Lighter text color when disabled
                    fontSize: '16px',
                    fontWeight: 'bold',
                    cursor: !userChoiceMade[curQ] ? 'not-allowed' : 'pointer', // No pointer when disabled
                  }}
                >
                  {curQ === STEP_NAMES.length - 1 ? (
                    <>
                      Submit <span style={{ marginLeft: '10px' }}>🚀</span>
                    </>
                  ) : (
                    <>
                      Next
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '10px',
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.293 3.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-7 7a1 1 0 11-1.414-1.414L17.586 12H4a1 1 0 110-2h13.586l-5.293-5.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <LayoutContainer style={{ border: '0px solid green' }}>
      <Main style={{ border: '0px solid black' }}>{renderMain()}</Main>
    </LayoutContainer>
  );
};

export default Persona;
