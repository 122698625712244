const Immediately = (props) => (
  <svg
    width="56"
    height="36"
    viewBox="0 0 56 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.865 1.9873C7.82133 -1.60042 0.5 1.70898 0.5 7.57655V28.4235C0.5 34.291 7.82133 37.6005 12.865 34.0127L25.5 25.025V28.4235C25.5 34.291 32.8213 37.6005 37.865 34.0127L52.5185 23.5892C56.4937 20.7615 56.4937 15.2385 52.5185 12.4107L37.865 1.9873C32.8213 -1.60042 25.5 1.70898 25.5 7.57655V10.9749L12.865 1.9873ZM5.6155 7.57655C5.6155 5.6207 8.05592 4.51758 9.73717 5.71348L24.3908 16.137C25.7157 17.0795 25.7157 18.9205 24.3908 19.863L9.73717 30.2865C8.05592 31.4825 5.6155 30.3792 5.6155 28.4235V7.57655ZM30.6155 7.57655C30.6155 5.6207 33.056 4.51758 34.7372 5.71348L49.3907 16.137C50.7157 17.0795 50.7157 18.9205 49.3907 19.863L34.7372 30.2865C33.056 31.4825 30.6155 30.3792 30.6155 28.4235V7.57655Z"
      fill="#8442C7"
    />
  </svg>
);
export default Immediately;
