const JobChange = (props) => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.6665 23.3335H23.3332C22.5596 23.3335 21.8178 23.0262 21.2708 22.4792C20.7238 21.9322 20.4165 21.1904 20.4165 20.4168V11.6668C20.4165 10.1197 21.0311 8.636 22.125 7.54204C23.219 6.44808 24.7027 5.8335 26.2498 5.8335H43.7498C45.2969 5.8335 46.7807 6.44808 47.8746 7.54204C48.9686 8.636 49.5832 10.1197 49.5832 11.6668V20.4168C49.5832 21.1904 49.2759 21.9322 48.7289 22.4792C48.1819 23.0262 47.4401 23.3335 46.6665 23.3335ZM26.2498 17.5002H43.7498V11.6668H26.2498V17.5002Z"
      fill="#3A49CF"
    />
    <path
      d="M58.3335 17.5H11.6668C8.44517 17.5 5.8335 20.1117 5.8335 23.3333V58.3333C5.8335 61.555 8.44517 64.1667 11.6668 64.1667H58.3335C61.5552 64.1667 64.1668 61.555 64.1668 58.3333V23.3333C64.1668 20.1117 61.5552 17.5 58.3335 17.5Z"
      fill="#3A49CF"
    />
    <path
      d="M43.75 40.8332C42.9765 40.8332 42.2346 40.5259 41.6876 39.9789C41.1406 39.4319 40.8333 38.6901 40.8333 37.9165V34.9998H20.4167C19.6431 34.9998 18.9013 34.6925 18.3543 34.1456C17.8073 33.5986 17.5 32.8567 17.5 32.0832C17.5 31.3096 17.8073 30.5678 18.3543 30.0208C18.9013 29.4738 19.6431 29.1665 20.4167 29.1665H49.5833C50.3569 29.1665 51.0988 29.4738 51.6457 30.0208C52.1927 30.5678 52.5 31.3096 52.5 32.0832C52.5 32.8567 52.1927 33.5986 51.6457 34.1456C51.0988 34.6925 50.3569 34.9998 49.5833 34.9998H46.6667V37.9165C46.6667 38.6901 46.3594 39.4319 45.8124 39.9789C45.2654 40.5259 44.5235 40.8332 43.75 40.8332Z"
      fill="white"
    />
  </svg>
);
export default JobChange;
