

const WithinAMonth = (props) => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.4726 0C52.609 0 53.5314 0.922396 53.5314 2.05884V4.11764H59.7079C63.1132 4.11764 65.8843 6.88884 65.8843 10.2941V70H4.11963V10.2941C4.11963 6.88884 6.89079 4.11764 10.2961 4.11764H16.4725V2.05884C16.4725 0.922359 17.3949 3.64583e-05 18.5314 3.64583e-05C19.6679 3.64583e-05 20.5902 0.922359 20.5902 2.05884V4.11764H49.4137V2.05884C49.4137 0.922359 50.3361 3.64583e-05 51.4725 3.64583e-05L51.4726 0ZM61.7667 20.5882H8.23731V65.8823H61.7667V20.5882ZM24.7079 45.2941V57.6471H12.3549V45.2941H24.7079ZM41.1785 45.2941V57.6471H28.8255V45.2941H41.1785ZM57.649 45.2941V57.6471H45.2961V45.2941H57.649ZM20.5902 49.4118H16.4726V53.5294H20.5902V49.4118ZM37.0608 49.4118H32.9431V53.5294H37.0608V49.4118ZM53.5314 49.4118H49.4137V53.5294H53.5314V49.4118ZM24.7079 28.8235V41.1765H12.3549V28.8235H24.7079ZM41.1785 28.8235V41.1765H28.8255V28.8235H41.1785ZM57.649 28.8235V41.1765H45.2961V28.8235H57.649ZM20.5902 32.9412H16.4726V37.0588H20.5902V32.9412ZM37.0608 32.9412H32.9431V37.0588H37.0608V32.9412ZM53.5314 32.9412H49.4137V37.0588H53.5314V32.9412ZM16.4726 8.23528H10.2961C9.16371 8.23528 8.23731 9.15768 8.23731 10.2941V16.4706H61.7667V10.2941C61.7667 9.15764 60.8402 8.23532 59.7079 8.23532H53.5314V10.2941C53.5314 11.4306 52.609 12.353 51.4726 12.353C50.3361 12.353 49.4137 11.4306 49.4137 10.2942V8.23528H20.5902V10.2941C20.5902 11.4306 19.6678 12.353 18.5314 12.353C17.3949 12.353 16.4726 11.4306 16.4726 10.2942V8.23528Z"
      fill="#5A3CD1"
    />
  </svg>
);

export default WithinAMonth;