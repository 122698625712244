const Education = (props) => (
  <svg
    width="68"
    height="70"
    viewBox="0 0 68 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.9742 21.7805L1.40071 8.52219C0.741963 8.23789 0 8.72329 0 9.44444V55.737C0 56.1392 0.235758 56.4997 0.603269 56.6592L31.1768 69.9175C31.8425 70.2018 32.5775 69.7164 32.5775 68.9953V22.7027C32.5775 22.3006 32.3417 21.94 31.9742 21.7805ZM25.6433 39.3517L5.94972 31.5646L5.79023 20.1647L26.0448 27.8826L25.6426 39.3448L25.6433 39.3517Z"
      fill="#E36C06"
    />
    <path
      d="M7.2666 4.19573V8.81398C7.2666 9.01507 7.38448 9.19536 7.57171 9.27163L32.5837 20.1168L8.05101 3.77291C7.71816 3.55102 7.27438 3.78677 7.27438 4.18895L7.2666 4.19573Z"
      fill="#E36C06"
    />
    <path
      d="M13.8359 0.507061V4.65365C13.8359 4.82008 13.9191 4.97955 14.0648 5.06971L32.5797 17.2255L14.6825 0.139568C14.3636 -0.165528 13.8366 0.0632981 13.8366 0.500161L13.8359 0.507061Z"
      fill="#E36C06"
    />
    <path
      d="M68.0043 55.7233V9.43077C68.0043 8.70961 67.2623 8.22421 66.6036 8.50852L36.03 21.7668C35.6625 21.9263 35.4268 22.2869 35.4268 22.6891V68.9816C35.4268 69.7027 36.1687 70.1881 36.8275 69.9038L67.401 56.6456C67.7685 56.4861 68.0043 56.1255 68.0043 55.7233Z"
      fill="#E36C06"
    />
    <path
      d="M60.4387 9.27805C60.619 9.19484 60.7439 9.01455 60.7439 8.8204V4.20215C60.7439 3.79997 60.3001 3.5642 59.9672 3.78609L35.4346 20.13L60.4465 9.28481L60.4387 9.27805Z"
      fill="#E36C06"
    />
    <path
      d="M53.941 5.06912C54.0797 4.97897 54.1699 4.81949 54.1699 4.65305V0.506466C54.1699 0.0626769 53.6428 -0.15922 53.3239 0.145894L35.4268 17.2318L53.9416 5.07604L53.941 5.06912Z"
      fill="#E36C06"
    />
  </svg>
);
export default Education;
