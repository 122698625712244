


const NeedLoanAssistance = (props) => (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M35.0015 56.2632C42.4527 56.2632 47.5753 55.2954 47.6535 54.3654C47.9779 50.4797 42.9356 48.4507 40.9021 47.6889C39.8117 47.2809 38.2241 46.0906 38.2241 45.3506C38.2241 44.8602 38.2241 44.2471 38.2241 43.4072C38.8545 42.7074 39.3308 41.2837 39.6163 39.6725C40.2804 39.4349 40.6591 39.054 41.1332 37.3961C41.6377 35.6284 40.3747 35.6871 40.3747 35.6871C41.3957 32.3081 40.0524 29.1323 37.801 29.3645C36.2481 26.6496 31.0475 29.9852 29.4187 29.7529C29.4187 30.685 29.8062 31.3826 29.8062 31.3826C29.2398 32.4602 29.4578 34.6042 29.6163 35.6871C29.5251 35.6871 28.3836 35.6979 28.8687 37.3961C29.3407 39.054 29.7205 39.4349 30.3847 39.6725C30.6689 41.2839 31.1463 42.7074 31.7789 43.4072C31.7789 44.2471 31.7789 44.8602 31.7789 45.3506C31.7789 46.0906 30.0904 47.3395 29.0977 47.6889C27.0534 48.4083 22.0231 50.4797 22.3475 54.3654C22.4256 55.2952 27.548 56.2632 35.0015 56.2632Z" fill="#8B3ADB"/>
<path d="M55.368 7.77783H45.0002V10.0001C45.0002 14.2882 41.5106 17.7779 37.2224 17.7779H32.778C28.4897 17.7779 25.0002 14.2882 25.0002 10.0001V7.77783H14.6323C11.1536 7.77783 8.3335 10.5979 8.3335 14.0766V63.7013C8.3335 67.1799 11.1536 70 14.6323 70H55.3679C58.8467 70 61.6668 67.1799 61.6668 63.7013V14.0766C61.6669 10.5979 58.8468 7.77783 55.368 7.77783ZM55.0003 63.3334H15.0002V20.5556H55.0003V63.3334Z" fill="#8B3ADB"/>
<path d="M32.7782 15.5556H37.2227C40.2859 15.5556 42.7782 13.0632 42.7782 10V5.55557C42.7782 2.49238 40.2859 0 37.2227 0H32.7782C29.7149 0 27.2227 2.49238 27.2227 5.55557V10C27.2227 13.0631 29.715 15.5556 32.7782 15.5556ZM31.6671 5.55557C31.6671 4.94143 32.1639 4.44445 32.7782 4.44445H37.2227C37.8368 4.44445 38.3338 4.94143 38.3338 5.55557V7.77779C38.3338 8.39193 37.8368 8.88891 37.2227 8.88891H32.7782C32.1639 8.88891 31.6671 8.39193 31.6671 7.77779V5.55557Z" fill="#8B3ADB"/>
</svg>

)

export default NeedLoanAssistance;