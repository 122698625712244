


const Retail = (props) => (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M13.125 4.375L0 17.5V21.875C0 21.875 8.75 26.25 17.5 26.25C26.25 26.25 35 21.875 35 21.875C35 21.875 43.75 26.25 52.5 26.25C61.25 26.25 70 21.875 70 21.875V17.5L56.875 4.375H13.125Z" fill="#4654D1"/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.375 65.6248V32.8942C7.94811 33.9698 12.6074 34.9998 17.5 34.9998C23.1984 34.9998 28.5803 33.6026 32.2983 32.3633C33.2904 32.0326 34.1978 31.7016 35 31.3916C35.8022 31.7016 36.7096 32.0326 37.7017 32.3633C41.4197 33.6026 46.8016 34.9998 52.5 34.9998C57.3926 34.9998 62.0519 33.9698 65.625 32.8942V65.6248H30.625V43.7498H17.5V65.6248H4.375ZM52.5 43.7498H43.75V56.8748H52.5V43.7498Z" fill="#4654D1"/>
</svg>

)

export default Retail;