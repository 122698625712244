const NotSure = (props) => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.25 49.5837C28.7303 47.7453 31.7468 46.667 35 46.667C38.2533 46.667 41.2697 47.7453 43.75 49.5837"
      stroke="#34B3CE"
      strokeWidth="3.75"
      strokeLinecap="round"
    />
    <path
      d="M43.7502 35C45.361 35 46.6668 33.0412 46.6668 30.625C46.6668 28.2088 45.361 26.25 43.7502 26.25C42.1393 26.25 40.8335 28.2088 40.8335 30.625C40.8335 33.0412 42.1393 35 43.7502 35Z"
      fill="#34B3CE"
    />
    <path
      d="M26.2502 35C27.861 35 29.1668 33.0412 29.1668 30.625C29.1668 28.2088 27.861 26.25 26.2502 26.25C24.6393 26.25 23.3335 28.2088 23.3335 30.625C23.3335 33.0412 24.6393 35 26.2502 35Z"
      fill="#34B3CE"
    />
    <path
      d="M20.4168 9.73498C24.7069 7.25334 29.6877 5.83301 35.0002 5.83301C51.1083 5.83301 64.1668 18.8914 64.1668 34.9997C64.1668 51.1078 51.1083 64.1663 35.0002 64.1663C18.8918 64.1663 5.8335 51.1078 5.8335 34.9997C5.8335 29.6873 7.25383 24.7064 9.73547 20.4163"
      stroke="#34B3CE"
      strokeWidth="3.75"
      strokeLinecap="round"
    />
  </svg>
);

export default NotSure;
