const Healthcare = (props) => (
  <svg
    width="80"
    height="70"
    viewBox="0 0 80 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0381 12.9999C43.9186 4.33524 48.799 0 58.5489 0C69.3158 0 78.0597 7.76251 78.0597 17.3243C78.0597 23.9151 75.2298 30.5058 70.6588 37.1133V28.0305C70.6588 27.2988 70.0618 26.7046 69.3267 26.7046H50.3579C49.6228 26.7046 49.0258 27.2988 49.0258 28.0305V39.1874H37.8167C37.0816 39.1874 36.4847 39.7816 36.4847 40.5133V59.3995C36.4847 60.1312 37.0816 60.7254 37.8167 60.7254H48.6278C45.4883 63.5972 42.2605 66.4578 39.0215 69.3296C19.5108 52.0053 0 34.681 0 17.3637C0 7.80211 8.74398 0.039322 19.5108 0.039322C29.2884 0.000811567 34.1679 4.33524 39.0381 12.9999ZM52.2095 29.8725V42.3388H39.6849V57.5337H52.2095V70H67.4754V57.5337H80V42.3332H67.4754V29.8668L52.2095 29.8725Z"
      fill="#54A701"
    />
  </svg>
);

export default Healthcare;
