import { createHashRouter } from 'react-router-dom';

import Home from './pages/index';
// import Index from './pages/Index/index';
import Notfound from './pages/Notfound';
import Persona from './pages/persona';
import Thankyou from './pages/thankyou';

const router = createHashRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/thankyou',
    element: <Thankyou />,
  },
  {
    path: '/persona',
    element: <Persona />,
  },
  {
    path: '*',
    element: <Notfound />,
  },
]);

export default router;
