import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App';


// Extend the Window interface to include 'gtag'
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

// Function to dynamically create a favicon based on 'u'
function updateFavicon() {
  const params = new URLSearchParams(window.location.search);
  const u = params.get('u'); // Get the 'u' parameter from the URL

  let faviconElement = document.querySelector("link[rel='icon']");
  if (!faviconElement) {
    faviconElement = document.createElement('link');
    faviconElement.setAttribute('rel', 'icon');
    document.head.appendChild(faviconElement);
  }

  // Generate a text-based favicon
  const canvas = document.createElement('canvas');
  canvas.width = 64; // Set canvas size for the favicon
  canvas.height = 64;
  const ctx = canvas.getContext('2d');

  if (ctx) {
    // Background
    ctx.fillStyle = '#fff'; // White background
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Text based on 'u'
    ctx.fillStyle = '#000'; // Black text
    ctx.font = '48px Arial'; // Font size and style
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(u || 'F', canvas.width / 2, canvas.height / 2); // Use 'u' or fallback to 'F'
  }

  // Set the canvas as the favicon
  faviconElement.setAttribute('href', canvas.toDataURL('image/png'));
}

// Call the function to update the favicon
updateFavicon();

// Function to trigger Google Ads conversion event
function triggerConversionEvent() {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-413149258/X8diCMKa74oYEMrQgMUB',
    });
    console.log('Google Ads conversion event triggered');
  } else {
    console.warn('gtag not found on window');
  }
}

// Example: Trigger the event after some condition
// Here, we simulate the event being triggered after page load (for demonstration purposes).
document.addEventListener('DOMContentLoaded', () => {
  triggerConversionEvent();
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<App />);
