import { useLocation } from 'react-router-dom';

export const BACHELORS = {
  BCOM: 'BCOM',
  BCA: 'BCA',
  BBA: 'BBA',
  BSC: 'BSC',
  BTECH: 'BTECH',
  OTHERS: 'Others',
};

export const MASTERS = {
  MCOM: 'MCOM',
  MCA: 'MCA',
  MBA: 'MBA',
  MSC: 'MSC',
  MTECH: 'MTECH',
  OTHERS: 'Others',
};

export const HIGHESTEDUCATION = {
  BACHELORS: "Bachelor's",
  MASTERS: "Master's",
};

export const EXPECTEDOUTCOME = {
  SALARYHIKE: 'Salary Hike',
  DOMAINEXPERTISE: 'Domain Expertise',
  JOBCHANGE: 'Job Change',
  OTHERS: 'Others',
};

export const PREFERREDPAYMENT = {
  SELFFUNDED: 'Self-Funded',
  EMPLOYERFUNDING: 'Employer Funding',
  LOANASSISTANCE: 'Need a Loan Assistance',
};

export const WORKEXPERIENCE = {
  YEARS01: 'Fresher',
  YEARS12: '1-2 years',
  YEARS24: '2-4 years',
  YEARS46: '4-6 years',
  YEARS710: '7-10 years',
  YEARS10PLUS: '10+ years',
};

export const PERCENTAGE = {
  OTHERS: 'Others',
};

export const COURSESTART = {
  IMMEDIATELY: 'Immediately',
  NOTSURE: 'Not Sure',
  WITHINAWEEK: 'Within a Week',
  WITHIN15DAYS: 'Within 15 Days',
  WITHINAMONTH: 'Within a Month',
};

export const WORKINDUSTRY = {
  AUTOMOBILE: 'Automobile',
  BFSI: 'BFSI',
  IT: 'IT',
  EDUCATION: 'Education',
  HEALTHCARE: 'Healthcare',
  MANUFACTURING: 'Manufacturing',
  RETAIL: 'Retail',
  OTHERS: 'Others',
};

export const SALARYRANGE = {
  LAKHS3: '0-3 Lakhs',
  LAKHS6: '3-6 Lakhs',
  LAKHS12: '6-12 Lakhs',
  LAKHS12PLUS: '12 Lakhs+',
};

export const LANGUAGES = {
  ENGLISH: 'English',
  HINDI: 'Hindi',
  BENGALI: 'Bengali',
  MALAYALAM: 'Malayalam',
  TAMIL: 'Tamil',
  PUNJABI: 'Punjabi',
  MARATHI: 'Marathi',
  KANNADA: 'Kannada',
  GUJARATI: 'Gujarati',
  NEPALI: 'Nepali',
  TELUGU: 'Telugu',
};

export const useStaticData = (bachelorOrMaster) => {
  const location = useLocation();
  const fullUrl = window.location.href;
  const queryStartIndex = fullUrl.indexOf('?');
  const queryEndIndex =
    fullUrl.indexOf('#') !== -1 ? fullUrl.indexOf('#') : undefined;

  const queryString = fullUrl.slice(queryStartIndex, queryEndIndex);
  const params = new URLSearchParams(queryString);

  const gParam = params.get('g');

  const highestEducationOptions =
    gParam === 'BACHELORS'
      ? ['12th', HIGHESTEDUCATION.BACHELORS]
      : [HIGHESTEDUCATION.BACHELORS, HIGHESTEDUCATION.MASTERS];

  const combinationQuestion = bachelorOrMaster
    ? `Which was your combination in ${bachelorOrMaster.toLowerCase()}?`
    : 'Which was your combination in PLACEHOLDER?';

  const percentageQuestion =
    bachelorOrMaster === HIGHESTEDUCATION.MASTERS
      ? `What was your percentage in masters's?`
      : bachelorOrMaster === HIGHESTEDUCATION.BACHELORS
      ? `What was your percentage in bachelor's?`
      : 'What was your percentage in 12th?';

  const data = [
    {
      qm: 'What is your preferred language for communication?',
      qs: '',
      response: [
        LANGUAGES.ENGLISH,
        LANGUAGES.HINDI,
        LANGUAGES.BENGALI,
        LANGUAGES.MALAYALAM,
        LANGUAGES.TAMIL,
        LANGUAGES.PUNJABI,
        LANGUAGES.MARATHI,
        LANGUAGES.KANNADA,
        LANGUAGES.GUJARATI,
        LANGUAGES.NEPALI,
        LANGUAGES.TELUGU,
      ],
      responseValues: [
        'English',
        'Hindi',
        'Bengali',
        'Malayalam',
        'Tamil',
        'Punjabi',
        'Marathi',
        'Kannada',
        'Gujarati',
        'Nepali',
        'Telugu',
      ],
    },
    {
      qm: 'What is your highest level of education completed?',
      qs: 'Select any one',
      response: highestEducationOptions,
      responseValues: highestEducationOptions.map((option) =>
        option === '12th' ? 'TWELFTH' : option.toUpperCase(),
      ),
    },
    {
      qm: combinationQuestion,
      qs: 'Select any one',
      response:
        bachelorOrMaster === HIGHESTEDUCATION.MASTERS
          ? Object.values(MASTERS)
          : Object.values(BACHELORS),
      responseValues:
        bachelorOrMaster === HIGHESTEDUCATION.MASTERS
          ? Object.keys(MASTERS)
          : Object.keys(BACHELORS),
    },
    {
      qm: percentageQuestion,
      qs: '',
      response: ["35-50%","50-65%","65-80%","80-90%","90-100%"],
      responseValues: ["35-50%","50-65%","65-80%","80-90%","90-100%"],
    },
    {
      qm: 'How many years of work experience do you have?',
      qs: 'Select the number of years',
      response: [
        WORKEXPERIENCE.YEARS01,
        WORKEXPERIENCE.YEARS12,
        WORKEXPERIENCE.YEARS24,
        WORKEXPERIENCE.YEARS46,
        WORKEXPERIENCE.YEARS710,
        WORKEXPERIENCE.YEARS10PLUS,
      ],
      responseValues: [
        'YEARS01',
        'YEARS12',
        'YEARS24',
        'YEARS46',
        'YEARS710',
        'YEARS10PLUS',
      ],
    },
    {
      qm: 'Which industry are you currently working in?',
      qs: 'Select your industry below',
      response: [
        WORKINDUSTRY.IT,
        WORKINDUSTRY.BFSI,
        WORKINDUSTRY.EDUCATION,
        WORKINDUSTRY.HEALTHCARE,
        WORKINDUSTRY.RETAIL,
        WORKINDUSTRY.MANUFACTURING,
        WORKINDUSTRY.AUTOMOBILE,
        WORKINDUSTRY.OTHERS,
      ],
      responseValues: [
        'IT',
        'BFSI',
        'EDUCATION',
        'HEALTHCARE',
        'RETAIL',
        'MANUFACTURING',
        'AUTOMOBILE',
        'OTHERS',
      ],
    },
    {
      qm: 'What is your salary range?',
      qs: ' ',
      response: [
        SALARYRANGE.LAKHS3,
        SALARYRANGE.LAKHS6,
        SALARYRANGE.LAKHS12,
        SALARYRANGE.LAKHS12PLUS,
      ],
      responseValues: ['LAKHS3', 'LAKHS6', 'LAKHS12', 'LAKHS12PLUS'],
    },
    {
      qm: 'What is your expected outcome out of the program?',
      qs: ' ',
      response: [
        EXPECTEDOUTCOME.SALARYHIKE,
        EXPECTEDOUTCOME.DOMAINEXPERTISE,
        EXPECTEDOUTCOME.JOBCHANGE,
        EXPECTEDOUTCOME.OTHERS,
      ],
      responseValues: ['SALARYHIKE', 'DOMAINEXPERTISE', 'JOBCHANGE', 'OTHERS'],
    },
    {
      qm: 'How would you like to pay the fees?',
      qs: ' ',
      response: [
        PREFERREDPAYMENT.SELFFUNDED,
        PREFERREDPAYMENT.EMPLOYERFUNDING,
        PREFERREDPAYMENT.LOANASSISTANCE,
      ],
      responseValues: ['SELFFUNDED', 'EMPLOYERFUNDING', 'LOANASSISTANCE'],
    },
    {
      qm: 'When are you planning to start your course?',
      qs: ' ',
      response: [
        COURSESTART.IMMEDIATELY,
        COURSESTART.WITHINAWEEK,
        COURSESTART.WITHIN15DAYS,
        COURSESTART.WITHINAMONTH,
        COURSESTART.NOTSURE,
      ],
      responseValues: [
        'IMMEDIATELY',
        'WITHINAWEEK',
        'WITHIN15DAYS',
        'WITHINAMONTH',
        'NOTSURE',
      ],
    },
  ];

  return { data };
};
