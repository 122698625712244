const BFSI = (props) => (
  <svg
    width="60"
    height="71"
    viewBox="0 0 60 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9996 32H8V34.2322C8 34.6552 8.31802 35 8.70817 35H17.2918C17.682 35 18 34.6552 18 34.2322L17.9996 32Z"
      fill="#E4296A"
    />
    <path
      d="M5.51263 30.9998H54.4863C55.4545 30.9998 55.8121 30.2161 55.8975 29.9771C55.9829 29.738 56.2108 28.9012 55.4894 28.2271L49.0568 22.23C48.076 22.499 47.0539 22.6584 45.9972 22.6584C44.317 22.6584 42.7191 22.2964 41.2636 21.6423L42.6622 23.0868C43.0387 23.4753 43.0419 24.1095 42.6717 24.5047C42.485 24.7039 42.2382 24.8035 41.9914 24.8035C41.7478 24.8035 41.5073 24.7072 41.3206 24.5147L31.4896 14.3434C31.1131 13.9549 31.1099 13.3207 31.4801 12.9255C31.8503 12.5303 32.4546 12.527 32.8312 12.9155L34.9669 15.1238C34.2962 13.5498 33.9164 11.8098 33.9164 9.97347C33.9164 9.3824 33.9702 8.8013 34.043 8.23013L31.0055 5.40097C30.4328 4.86634 29.5722 4.86634 28.9994 5.40097L4.51298 28.224C3.78841 28.8981 4.01622 29.7315 4.10481 29.9739C4.18708 30.2164 4.54441 30.9998 5.51263 30.9998Z"
      fill="#E4296A"
    />
    <path
      d="M46 21C51.5146 21 56 16.2907 56 10.5C56 4.70966 51.5149 0 46 0C40.4854 0 36 4.70932 36 10.5C36 16.2903 40.4882 21 46 21ZM42.756 6.87236C42.756 5.81099 43.0141 4.94551 43.5273 4.27926C44.0405 3.61301 44.6999 3.23093 45.4993 3.12968V2.21195H46.5412V3.12968C47.2877 3.24725 47.8942 3.57711 48.3576 4.11598C48.821 4.65485 49.1165 5.40275 49.241 6.35309L47.4152 6.66008C47.3032 5.92526 47.0109 5.43208 46.5412 5.17742V8.79921C47.664 9.18784 48.426 9.66795 48.8273 10.2362C49.3031 10.9155 49.5426 11.7777 49.5426 12.8195C49.5426 13.9788 49.272 14.9357 48.7309 15.6804C48.1897 16.4282 47.4618 16.8854 46.5443 17.052V18.7829H45.5023V17.0977C43.7668 16.8332 42.7559 15.4909 42.4667 13.0775L44.3546 12.8195C44.4324 13.3061 44.5755 13.7306 44.787 14.0931C44.9985 14.4556 45.238 14.7169 45.5024 14.8769L45.4992 10.9971C43.6704 10.3276 42.756 8.95598 42.756 6.87236Z"
      fill="#E4296A"
    />
    <path d="M11 37H15V59H11V37Z" fill="#E4296A" />
    <path
      d="M8.00042 61.7678V64H18V61.7678C18 61.3448 17.682 61 17.2918 61H8.70817C8.31802 61 8.00042 61.3448 8.00042 61.7678Z"
      fill="#E4296A"
    />
    <path
      d="M42.0004 61.7678V64H52V61.7678C52 61.3448 51.682 61 51.2918 61H42.7082C42.3147 61 42.0004 61.3448 42.0004 61.7678Z"
      fill="#E4296A"
    />
    <path d="M44 37H49V59H44V37Z" fill="#E4296A" />
    <path
      d="M51.9996 32H42V34.2322C42 34.6552 42.318 35 42.7082 35H51.2918C51.682 35 52 34.6552 52 34.2322L51.9996 32Z"
      fill="#E4296A"
    />
    <path d="M28 37H32V59H28V37Z" fill="#E4296A" />
    <path
      d="M34.9996 32H25V34.2322C25 34.6552 25.318 35 25.7082 35H34.2918C34.682 35 35 34.6552 35 34.2322L34.9996 32Z"
      fill="#E4296A"
    />
    <path
      d="M25.0004 61.7678V64H35V61.7678C35 61.3448 34.682 61 34.2918 61H25.7082C25.318 61 25.0004 61.3448 25.0004 61.7678Z"
      fill="#E4296A"
    />
    <path
      d="M59.6035 68.0938C59.2576 66.3021 57.7777 65 56.0897 65H3.90446C2.21322 65 0.736576 66.302 0.390621 68.0938L0.0158593 70.0347C-0.0449998 70.3611 0.0831241 70.5938 0.166402 70.7049C0.24968 70.816 0.43226 71 0.739758 71H59.2602C59.5645 71 59.7503 70.816 59.8336 70.7049C59.9169 70.5938 60.045 70.3611 59.9841 70.0347L59.6035 68.0938Z"
      fill="#E4296A"
    />
  </svg>
);
export default BFSI;
