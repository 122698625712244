const Within15Days = (props) => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.75 20.25H55.25M14.4167 2.75V8.58333M43.5833 2.75V8.58333M14.4167 31.9167H43.5833V43.5833H14.4167V31.9167ZM12.0833 55.25H45.9167C49.1836 55.25 50.8173 55.25 52.065 54.6142C53.1625 54.055 54.055 53.1625 54.6142 52.065C55.25 50.8173 55.25 49.1836 55.25 45.9167V17.9167C55.25 14.6497 55.25 13.0162 54.6142 11.7684C54.055 10.6708 53.1625 9.77838 52.065 9.21914C50.8173 8.58333 49.1836 8.58333 45.9167 8.58333H12.0833C8.81637 8.58333 7.18287 8.58333 5.93506 9.21914C4.83743 9.77838 3.94505 10.6708 3.3858 11.7684C2.75 13.0162 2.75 14.6497 2.75 17.9167V45.9167C2.75 49.1836 2.75 50.8173 3.3858 52.065C3.94505 53.1625 4.83743 54.055 5.93506 54.6142C7.18287 55.25 8.81635 55.25 12.0833 55.25Z"
      stroke="#D139C2"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Within15Days;
